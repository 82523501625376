import React from "react";
import Header from "../Header";
import OrigoFooter from "../OrigoFooter";
interface ILayout {
  children: React.ReactNode;
}
const Layout = (props: ILayout) => {
  const { children } = props;
  return (
    <>
      {/* <Header title={"HID Administration"} /> */}
      <div className="hid-fixed-container">{children}</div>
      <OrigoFooter />
    </>
  );
};
export default Layout;
