import { useState, useEffect, useRef } from "react";
import {
  Button,
  Notification,
  Modal,
  ModalVariants,
  Typography,
  Card,
  Loader,
} from "@hid-galaxy-ui/galaxy-react";
import { TypographyVariantEnum } from "@hid-galaxy-ui/galaxy-react/components/Typography/typographyEnum";
import SockJS from "sockjs-client";
import * as Stomp from "stompjs";
import { connect } from "../../../../App";
import { useDispatch, useSelector } from "react-redux";
import { loginInfoData } from "../../../../reducers/userInfoReducer";
import { applyTemplateData } from "../../../../reducers/applyTemplateReducer";

import { socketUrl } from "../../../../utils/apiServices";
import {
  getAvailableFirmwareService,
  resetReaderService,
} from "../../../../services/readerAPIService";
import resetReaderConfig from "../../../../assets/svgs/resetReaderConfig.svg";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../../../utils/i18nUtils";
import { buildNotification } from "../../../../utils/notification";
import {
  setRunningProcesses,
  setSelectedCorrelationId,
  setIsResetReaderProgressModalOpen,
} from "../../../../reducers/applyTemplateReducer";
import ApplyResetReader from "../applyResetReader";
import ReviewResetModal from "./ReviewResetModal";
import {
  setAvailableFirmwareVersions,
  setLatestFWVersion,
  upgradeFirmwareData,
} from "../../../../reducers/upgradeFirmwareReducer";
import ProtocolModal from "../../readersApplyTemplate/ProtocolModal";
import { readerFirmwareErrors } from "../../upgradeFirmware";

interface IResetReaderProps {
  closeModal: () => void;
  deviceDetails: any;
  selectedGatewayDevice: any;
  applyResetOpen: boolean;
}

export default function ResetReader(props: IResetReaderProps) {
  const { t } = useTranslation(NAMESPACE.READER);
  const dispatch = useDispatch();
  const { closeModal, deviceDetails, selectedGatewayDevice } = props;
  const { selectedCustomerInfo } = useSelector(loginInfoData);
  const customerId = selectedCustomerInfo?.customerId?.toString();

  const { isResetReaderProgressModalOpen } = useSelector(applyTemplateData);
  const [toasts, setToasts] = useState([]) as any;
  const [showLoader, setShowLoader] = useState(false);
  const { latestFWVersion } = useSelector(upgradeFirmwareData);
  const [showReviewResetModal, setShowReviewResetModal] = useState(false);
  const [versionCompatibilityError, setVersionCompatibilityError] =
    useState<boolean>(false);
  let stompClient: Stomp.Client;
  const [showProtocolModal, setShowProtocolModal] = useState<any>({
    show: false,

    content: "",
  });
  const apiCallInProgress = useRef(false); // Ref to track API call state
useEffect(() => {
  if (deviceDetails.fwDisplayName !== latestFWVersion) {
    setVersionCompatibilityError(true);
  }else{
    setVersionCompatibilityError(false);
  }
}, [latestFWVersion])

  useEffect(() => {
    if (apiCallInProgress.current) return; // Prevent multiple API calls
    apiCallInProgress.current = true; 

    const fetchData = async () => {
      try {
        const { deviceDetails } = props;
        var socket = new SockJS(socketUrl);
        stompClient = Stomp.over(socket);
        stompClient.connect({}, () => {});
        if (deviceDetails.fwDisplayName !== latestFWVersion) {
          setVersionCompatibilityError(true);
        }else{
          setVersionCompatibilityError(false);
        }

        const payload = {
          deviceId: deviceDetails.id || "",
          currentSoftwareVersion: readerFirmwareErrors.includes(
            deviceDetails.fwDisplayName
          )
            ? ""
            : deviceDetails.fwDisplayName,
        };
        const availableFirmwareList: any = await getAvailableFirmwareService({
          connectedDevices: [payload],
        });

        if (availableFirmwareList && availableFirmwareList.data) {
          const latestFw =
            availableFirmwareList?.data?.applicableSoftwareVersions.length === 0
              ? availableFirmwareList?.data?.currentSoftwareVersion
              : availableFirmwareList?.data?.applicableSoftwareVersions[
                  availableFirmwareList?.data?.applicableSoftwareVersions
                    .length - 1
                ];
          latestFw &&
            dispatch(setLatestFWVersion(latestFw?.softwareVersion || latestFw));
          dispatch(setAvailableFirmwareVersions(availableFirmwareList));
        }
      } catch (err) {
      } finally {
        apiCallInProgress.current = false; 
      }
    };

    fetchData();
  }, [props.deviceDetails]); 

  const resetCloseHandler = () => {
    closeModal();
  };

  const resetClickHandler = () => {
    resetReader(deviceDetails);
  };

  const resetReader = async (
    deviceDetails: any,
    restrictProtocol: boolean | string = true
  ) => {
    let message = "";
    setShowLoader(true);
    try {
      if (customerId) {
        const payload = {
          connectedDeviceId: deviceDetails.id,
          firmwareVersion: deviceDetails.fwDisplayName,
        };
        const response: any = await resetReaderService(
          payload,
          customerId,
          selectedGatewayDevice,
          restrictProtocol
        );

        if (
          response &&
          response.status.code === 202 &&
          response.data.correlationId
        ) {
          const correlationId = response.data.correlationId;
          setShowLoader(false);
          dispatch(setIsResetReaderProgressModalOpen(true));
          dispatch(
            setRunningProcesses({
              [correlationId]: {
                isCompleted: false,
                type: "reset",
                deviceName: deviceDetails.id,
              },
            })
          );
          dispatch(setSelectedCorrelationId(correlationId));
          connect(correlationId, "resetReader");
          closeModal();
        } else if (response && response.statusCode) {
          message = response.statusDescription;
        }
      } else {
        message = t("READERS.ORGANIZATION_NOT_FOUND");
      }
    } catch (err: any) {
      if (
        err.response.data?.statusDescription.indexOf(
          "communication protocol"
        ) !== -1
      ) {
        // setCustomHeader("restrictProtocolChange")

        setShowProtocolModal((prev: any) => {
          return {
            ...prev,
            show: true,
            content: err.response.data.statusDescription,
          };
        });
        return;
      }
      if (err && err.response.status === 409) {
        message = t("READERS.DEVICE_OPERATION_INPROGRESS");
      } else {
        message = t("READERS.UNEXPECTED_ERROR");
      }
    }
    if (!!message.length) {
      setToasts([buildNotification("Error", message)]);
    }
  };

  const showNotification = () => {
    return (
      <Notification
        toasts={toasts}
        setToasts={setToasts}
        isSticky={false}
        isAutoClose={true}
      />
    );
  };
  const onReviewClose = () => {
    setShowReviewResetModal(false);
  };
  const handleResetButton = () => {
    if (deviceDetails.fwDisplayName === latestFWVersion) {
      setShowReviewResetModal(true);
    }
  };
  const resetReaderModal = (
    <Modal
      size={ModalVariants.Large}
      isFocusTrapActive={false}
      isDisplayCloseIcon={true}
      onClose={closeModal}
    >
      <div className="hid-grid__column hid-grid__column--12-xs hid-origo__mt-10">
        <div className="hid-flex hid-flex-jc-center hid-spacing__pb-04">
          <Typography
            variant={TypographyVariantEnum.H1}
            className={"hid-spacing__mr-2"}
          >
            {t("READERS.ADVANCED")}
          </Typography>
        </div>
        <Card>
          <div
            className="hid-grid "
            // onClick={resetClickHandler}
          >
            <div className="hid-grid__column hid-grid__column--2-xs hid-origo__linq__reset-img-icon">
              <img src={resetReaderConfig} />
            </div>
            <div className="hid-grid__column hid-grid__column--10-xs">
              <div className="hid-origo__linq__reset-label-lg">
                {t("READERS.RESET_READER_TITLE")}
              </div>
              <div className="hid-origo__linq__reset-label-sm hid-origo__mt-10">
                {t("READERS.RESET_READER_DESCRIPTION_TEXT")}{" "}
                {showLoader && <Loader />}
              </div>
            </div>
          </div>
        </Card>
        <div className="hid-grid">
          <div className="hid-grid__column hid-grid__column--12-xs hid-origo__flex-center hid-origo__mt-35">
            {versionCompatibilityError ? (
              <div className="hid-origo__linq--reset-error-message">
                {t("READERS.RESET_READER_MESSAGE")}
              </div>
            ) : (
              <Button
                variant="primary"
                label={t("READERS.RESET")}
                onClick={() => handleResetButton()}
                // onClick={resetClickHandler}
                className="hid-spacing__mr-02"
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
  return (
    <>
      {showReviewResetModal ? (
        <ReviewResetModal onClose={onReviewClose} onReset={resetClickHandler} />
      ) : !isResetReaderProgressModalOpen ? (
        resetReaderModal
      ) : (
        <ApplyResetReader deviceName={deviceDetails.id} />
      )}

      {showProtocolModal.show && (
        <ProtocolModal
          onClose={() =>
            setShowProtocolModal({
              show: false,
              data: {},
              readers: [],
              content: "",
            })
          }
          title={t("READERS.APPLYING_RESET").toUpperCase()}
          onContinue={() => resetReader(deviceDetails, false)}
          content={showProtocolModal.content}
        />
      )}

      {toasts.length > 0 && showNotification()}
    </>
  );
}
