import { useState } from "react";
import {
  Button,
  Iconography,
  IconographySizes,
  IconographyThemes,
  Modal,
  ModalVariants,
  Typography,
  NotificationVariants,
} from "@hid-galaxy-ui/galaxy-react";
import { TypographyVariantEnum } from "@hid-galaxy-ui/galaxy-react/components/Typography/typographyEnum";
import { useDispatch, useSelector } from "react-redux";
import {
  applyTemplateData,
  setNotification,
} from "../../../../reducers/applyTemplateReducer";

import DCIDIcon from "../../../../assets/svgs/dcid_icon.svg";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../../../utils/i18nUtils";
import lightening from "../../../../assets/svgs/lightening.svg";
import readerProgress from "../../../../assets/svgs/readersProgress.svg";
import { setIsResetReaderProgressModalOpen } from "../../../../reducers/applyTemplateReducer";
import readerBlue from "../../../../assets/svgs/reader-blue.svg";
import readergreen from "../../../../assets/svgs/reader_green_icon.svg";

interface IApplyResetReaderProps {
  deviceName: string;
}

export enum resetApplyStatus {
  SUCCESS = "APPLY_CONFIG_SUCCESS",
  FAILURE = "APPLY_CONFIG_FAILED",
}

export default function ApplyResetReader(props: IApplyResetReaderProps) {
  const { t } = useTranslation(NAMESPACE.READER);
  const dispatch = useDispatch();
  const { deviceName } = props;
  const {
    resetReaderResponse,
    isResetReaderProgressModalOpen,
    runningProcesses = {},
    selectedCorrelationId,
  } = useSelector(applyTemplateData);

  const isCompleted =
    (runningProcesses &&
      runningProcesses[selectedCorrelationId]?.isCompleted) ||
    false;

  const [showWarning, setShowWarning] = useState<boolean>(true);

  const applyResetCloseHandler = () => {
    if (isCompleted) {
      if (
        resetReaderResponse[selectedCorrelationId][0]["applyDcidStatus"] ===
        resetApplyStatus.SUCCESS
      ) {
        dispatch(
          setNotification([
            {
              id: "1",
              icon: <Iconography icon="circleCheck" />,
              variant: NotificationVariants.Success,
            },
          ])
        );
      } else {
        dispatch(
          setNotification([
            {
              id: "1",
              icon: <Iconography icon="circleX" />,
              variant: NotificationVariants.Error,
            },
          ])
        );
      }
    } else {
      dispatch(
        setNotification([
          {
            id: "1",
            icon: (
              <img
                className="hid-spacing__pr-05"
                src={isCompleted ? readergreen : readerBlue}
                alt="reader-icon"
              />
            ),
            variant: isCompleted
              ? NotificationVariants.Success
              : NotificationVariants.Info,
          },
        ])
      );
    }
    dispatch(setIsResetReaderProgressModalOpen(false));
  };

  const applyReset = (
    <Modal
      size={ModalVariants.Large}
      isFocusTrapActive={false}
      isDisplayCloseIcon={false}
    >
      <div className="hid-grid__column hid-grid__column--12-xs hid-origo__mt-10">
        <div className="hid-flex hid-flex-jc-center hid-spacing__pb-04">
          <Typography
            variant={TypographyVariantEnum.H1}
            className={"hid-spacing__mr-2"}
          >
            {t("READERS.RESET_CONFIGURATION")}
          </Typography>
        </div>
        <div className="hid-grid hid-grid--center-xs hid-spacing__mb-03">
          <div className="hid-grid__column hid-grid__column--5-xs  hid-hid-grid--center-xs hid-origo__mt-10">
            <div className="hid-origo__grey-card hid-layout__mt-04">
              <div className="hid-grid">
                <div className="hid-grid__column hid-grid__column--12-xs hid-origo__mt-10">
                  <img src={DCIDIcon} alt="dcid-icon" />
                </div>
                <div className="hid-grid__column hid-grid__column--12-xs hid-origo__mt-10">
                  {t("READERS.CONFIGURATION_TEXT")}
                </div>
                <div className="hid-grid__column hid-grid__column--12-xs">
                  <span className="hid-origo__fs-32 hid-origo__bold-text">
                    {t("READERS.RESET_TEXT")}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="hid-grid__column hid-grid__column--7-xs hid-origo__mt-10">
            <div className="hid-origo__right-card hid-layout__mt-04">
              <div className="hid-grid">
                <div className="hid-grid__column hid-grid__column--10-xs ">
                  <div className="hid-grid">
                    <div className="hid-grid__column hid-grid__column--8-xs hid-origo__mt-10 hid-origo__flex-center">
                      <div className="hid-grid hid-origo__flex-center">
                        <div className="hid-grid__column hid-grid__column--12-xs">
                          <Iconography
                            icon="arrowRightToBracket"
                            size={IconographySizes.Medium}
                          />
                        </div>
                        {isCompleted ? (
                          <div className="hid-grid__column hid-grid__column--12-xs hid-origo__mt-10">
                            {t("READERS.OPERATION_COMPLETED")}
                          </div>
                        ) : (
                          <div className="hid-grid__column hid-grid__column--12-xs hid-origo__mt-10 ">
                            {t("READERS.CURRENTLY_APPLYING_TO")}
                          </div>
                        )}
                        <div className="hid-grid__column hid-grid__column--12-xs hid-origo__mt-10">
                          <span className="hid-origo__bold-text hid-origo__linq__reset-label-sm">
                            {deviceName
                              ? deviceName
                              : resetReaderResponse[selectedCorrelationId]?.[0]?.[
                                  "id"
                                ]}
                          </span>
                        </div>
                        <div className="hid-grid__column hid-grid__column--12-xs hid-origo__mt-10">
                          <span className="hid-origo__bold-text hid-origo__linq__reset-label-sm">
                            {selectedCorrelationId &&
                            resetReaderResponse &&
                            resetReaderResponse[selectedCorrelationId] ? (
                              resetReaderResponse[selectedCorrelationId][0][
                                "applyDcidStatus"
                              ] === resetApplyStatus.SUCCESS ? (
                                <>
                                  <Iconography
                                    icon="circleCheck"
                                    size={IconographySizes.Medium}
                                    theme={IconographyThemes.Success}
                                  />
                                  &nbsp;{t("READERS.SUCCESS_TEXT")}
                                </>
                              ) : (
                                <>
                                  {" "}
                                  <Iconography
                                    icon="circleX"
                                    size={IconographySizes.Medium}
                                    theme={IconographyThemes.Error}
                                  />
                                  &nbsp;{t("READERS.FAILED_TEXT")}
                                 <div> <Iconography
                                    icon="circleInfo"
                                    size={IconographySizes.Medium}
                                    theme={IconographyThemes.Info}
                                  />
                                  &nbsp;{t("READERS.READER_VERIFY")}
                                  </div>
                                </>
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="hid-grid__column hid-grid__column--4-xs hid-origo__mt-10 hid-origo__flex-center">
                      <img src={readerProgress} alt="reader-progress" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showWarning && (
          <div className="hid-flex hid-flex-jc-between warning-box">
            <div className="hid-flex">
              <img src={lightening} alt="lightening" />
              <Typography
                variant={TypographyVariantEnum.Label}
                className={"hid-spacing__ml-05 hid-origo__mt-2"}
              >
                {t("READERS.DONT_DISCONNECT")}
              </Typography>
            </div>
            <Iconography
              icon="xmark"
              onClick={() => setShowWarning(false)}
              className="hid-origo__linq__cursor-pointer"
            />
          </div>
        )}
        <div
          className={`hid-flex hid-flex-jc-center ${showWarning} ? '' : hid-origo__mt-35`}
        >
          <Button
            variant="primary"
            onClick={applyResetCloseHandler}
            label={t("READERS.CLOSE")}
          />
        </div>
      </div>
    </Modal>
  );
  return <>{isResetReaderProgressModalOpen ? applyReset : ""}</>;
}
