import React from "react";
import Header from "./widgets/HeaderComponent";
import { Typography } from "@hid-galaxy-ui/galaxy-react";
import { TypographyVariantEnum } from "@hid-galaxy-ui/galaxy-react/components/Typography/typographyEnum";
import Layout from "./widgets/Layout";

interface Props {
    children : React.ReactNode
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends React.Component<Props,State> {
    constructor(props:Props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error:Error) {
      return { hasError: true };
    }
  
    componentDidCatch(error:Error, errorInfo:React.ErrorInfo) {
      // if needed attaching a logging service
      console.error("Error Boundary Caught Error : ",error, errorInfo);
    }
  
    render() {
      if (this.state.hasError) {
        // Rendering custom error component
        return <>
          <Header/>
        <Layout>
      
        <Typography 
         variant={TypographyVariantEnum.H3}
         className="hid-flex hid-flex-jc-center hid-flex-ai-center hid-full-width"
        >Something Went Wrong ! </Typography>
        </Layout>
        </>
      }
  
      return this.props.children; 
    }
  }

  export default ErrorBoundary;