import { ILinks, IDevices } from "../pages/Linq/readers/downloadGateway/types";
import {
  getSessionId,
  Get,
  Post,
  baseUrl,
  Put,
  Patch,
  Delete,
  FileDownload,
} from "../utils/apiServices";

const linqEndpointPrefix = "linq-cloud";
const getGatewayDeviceEndpoint = `${linqEndpointPrefix}/customer/:custId/generic-device?page=:page&page-size=:page-size&sort-order=:sort-order&device-name=:device-name`;
const discoverReadersEndpoint = `${linqEndpointPrefix}/customer/:custId/generic-device/:deviceId/action`;
const applyTemplateEndpoint = `${linqEndpointPrefix}/customer/:custId/generic-device/:deviceId/configuration`;
export const searchDCIDEndpoint = `${baseUrl}/${linqEndpointPrefix}/configuration/search`;
const locateReaderEndpoint = `${linqEndpointPrefix}/customer/:custId/generic-device/:deviceId/locate`;
const validateFirmwareEndpoint = `${linqEndpointPrefix}/configuration/firmware-version-validator`;
const resetReaderEndpoint = `${linqEndpointPrefix}/customer/:custId/generic-device/:gatewayIdDeviceId/reset`;
// const availableFirmwareEndpoint = `${linqEndpointPrefix}/configuration/applicable-firmware-versions`;
const availableFirmwareEndpoint = `${linqEndpointPrefix}/configuration/connected-device/:connectedDeviceId/applicable-firmware-versions`;
const updateFirmwareEndpoint = `${linqEndpointPrefix}/customer/:custId/generic-device/:gatewayIdDeviceId/firmware/update`;
const fetchDCIDEndpoint = `${baseUrl}/${linqEndpointPrefix}/configuration/:configId`;
const changeGatewayNameEndpoint = `${baseUrl}/${linqEndpointPrefix}/customer/:custId/generic-device/:deviceId`;
const changeReaderNameEndpoint = `${baseUrl}/${linqEndpointPrefix}/customer/:custId/generic-device/:genDeviceId/device/:deviceId`;
const onboardGatewayEndpoint = `${baseUrl}/${linqEndpointPrefix}/customer/:custId/generic-device/:deviceId/device-onboard`;
const saveToDraftEndpoint = `${baseUrl}/${linqEndpointPrefix}/configuration`;
const pendingreEmmitCallback = `${baseUrl}/${linqEndpointPrefix}/customer/:customer_id/user/:email/emit-message`;
const downloadGatewayEndpoint = `${baseUrl}/${linqEndpointPrefix}/customer/:customer_id/download-gateway`;

interface IStatus {
  code: number;
  description: string;
}

interface IGateway {
  genericDevicesPayload: {
    devices: IDevices[];
  };
  totalPages: number;
  totalRecords: number;
  links: ILinks[];
}
interface IGetGateawyDeviceResponse {
  data: { genericDevicesPayload: IGateway };
  status: IStatus;
}
interface IDiscoverReaderRequest {
  type: string;
}
interface IApplyTemplateRequest {
  deviceConfigurationIdentifier: string;
  connectedDeviceId: string[];
}
interface ILocateReaderRequest {
  connectedDeviceId: string;
}
interface IValidateFirmwareRequest {
  deviceConfigurationIdentifier: string;
  connectedDevice: { deviceId: string; version: string }[];
}
interface IValidateFirmwareResponse {
  status: {
    code: number;
    message: string;
  };
  data: {
    invalidDevice: {
      deviceId: string;
      version: string;
    }[];
  };
}
interface IApplyTemplateResponse {
  data: { correlationId: string };
  status: { code: number; message: string };
}

export interface ILocateReaderResponse {
  status: {
    code: number;
    message: string;
  };
  data: string;
}
interface IAvailableFirmwareRequest {
  connectedDevices: {
    deviceId: string;
    currentSoftwareVersion: string;
  }[];
}

export interface IFirewareVersionList {
  deviceId: string;
  deviceType: string;
  currentSoftwareVersion: string;
  applicableSoftwareVersions: {
    softwareVersion: string;
    isLatest: boolean;
    preconditions?: {
      gatewayVersions: string[];
    };
  }[];
}

export interface IAvailableFirmwareResponse {
  status: {
    code: number;
    message: string;
  };
  // data: { data: IFirewareVersionList[] };
  data: IFirewareVersionList;
}

export interface IResetReaderRequest {
  connectedDeviceId: string;
  firmwareVersion: string;
}

export interface IResetReaderResponse {
  data: { correlationId: string };
  status: { code: number; message: string };
}

export interface IResetReaderErrorResponse {
  statusCode: number;
  statusDescription: string;
}
export interface IChangeGatewayNameRequest {
  name: string;
  description?: string;
  deviceType: string;
  category: string;
  protocol: string;
}

export const getGatewayDeviceService = async (
  customerId: any,
  page: any,
  pageSize: any,
  deviceName: string = "",
  sortOrder = "DESC"
) => {
  const getGatewayDeviceURL = getGatewayDeviceEndpoint
    .replace(":custId", customerId)
    .replace(":page", page)
    .replace(":page-size", pageSize)
    .replace(":sort-order", sortOrder)
    .replace(":device-name", encodeURIComponent(deviceName));
  return await Get<IGetGateawyDeviceResponse, any>(
    getGatewayDeviceURL,
    {},
    {
      headers: {
        session: getSessionId(),
      },
    },
    false
  );
};
export const discoverReaderService = async (
  data: {
    gatewayId: string;
  },
  customerId?: string
) => {
  const discoverReadesEndpoint = discoverReadersEndpoint.replace(
    ":deviceId",
    // "09e25c38-dcb8-4b40-b8af-c507ef14512e"
    data.gatewayId
  );
  const discoverReadersURL = customerId
    ? discoverReadesEndpoint.replace(":custId", customerId)
    : discoverReadesEndpoint;
  return await Post<null, IDiscoverReaderRequest>(
    discoverReadersURL,
    { type: "DISCOVERY" },
    {
      headers: {
        session: getSessionId(),
      },
    }
  );
};
export const applyTemplateService = async (
  data: {
    gatewayId: string;
  },
  payload: IApplyTemplateRequest,
  customerId: string,
  restrictProtocolChange: boolean | string = true,
  restrictOsdpVersionChange: boolean | string = true
) => {
  const applyDCIDEndpoint: string = applyTemplateEndpoint.replace(
    ":deviceId",
    data.gatewayId
  );
  const applyTemplateURL = applyDCIDEndpoint.replace(":custId", customerId);

  return await Put<IApplyTemplateResponse, IApplyTemplateRequest>(
    applyTemplateURL,
    payload,
    {
      headers: {
        restrictProtocolChange: restrictProtocolChange ? "true" : "false",
        restrictOsdpVersionChange: restrictOsdpVersionChange ? "true" : "false",
      },
    }
  );
};
export const locateReaderService = async (
  data: {
    gatewayId: string;
  },
  payload: ILocateReaderRequest,
  customerId: string
) => {
  const locateReaderURL: string = locateReaderEndpoint
    .replace(":deviceId", data.gatewayId)
    .replace(":custId", customerId);
  return await Post<ILocateReaderResponse, ILocateReaderRequest>(
    locateReaderURL,
    payload,
    {
      headers: {
        session: getSessionId(),
      },
    }
  );
};
export const validateFirmwareService = async (
  payload: IValidateFirmwareRequest
) => {
  return await Post<IValidateFirmwareResponse, IValidateFirmwareRequest>(
    validateFirmwareEndpoint,
    payload,
    {
      headers: {
        session: getSessionId(),
      },
    }
  );
};

export const resetReaderService = async (
  payload: IResetReaderRequest,
  customerId: string,
  gatewayIdDeviceId: string,
  restrictProtocolChange: boolean | string = true
) => {
  const resetReaderURL: string = resetReaderEndpoint
    .replace(":gatewayIdDeviceId", gatewayIdDeviceId)
    .replace(":custId", customerId);

  return await Put<IResetReaderResponse, IResetReaderRequest>(
    resetReaderURL,
    payload,
    {
      headers: {
        restrictProtocolChange: restrictProtocolChange?"true":"false",
      },
    }
  );
};

export const getAvailableFirmwareService = async (
  payload: IAvailableFirmwareRequest
) => {
  return await Get<IAvailableFirmwareResponse, IAvailableFirmwareRequest>(
    availableFirmwareEndpoint.replace(
      ":connectedDeviceId",
      payload.connectedDevices[0]?.deviceId
    ),
    // availableFirmwareEndpoint,
    payload,
    {
      headers: {
        session: getSessionId(),
      },
    }
  );
};

// https://linqcloudservice.test.origo.hidcloud.com/origo/middleware/linq-cloud/configuration/connected-device/EZ4WY-3ID28-07QY2-XD14F-90XUN/applicable-firmware-versions

export const updateFirmwareService = async (
  payload: any,
  customerId: string,
  gatewayIdDeviceId: string
) => {
  const updateFirmwareURL: string = updateFirmwareEndpoint
    .replace(":gatewayIdDeviceId", gatewayIdDeviceId)
    .replace(":custId", customerId);

  return await Put<IResetReaderResponse, IResetReaderRequest>(
    updateFirmwareURL,
    payload,
    {
      headers: {
        session: getSessionId(),
      },
    }
  );
};
export const searchDCIDService = async (payload: any) => {
  return await Post<any, any>(searchDCIDEndpoint, payload, {
    headers: {
      session: getSessionId(),
    },
  });
};
export const fetchDCIDService = async (payload: string) => {
  const endpoint = fetchDCIDEndpoint.replace(":configId", payload);
  return await Get<any, any>(
    endpoint,
    {},
    {
      headers: {
        session: getSessionId(),
      },
    }
  );
};
export const changeGatewayNameService = async (
  payload: IChangeGatewayNameRequest,
  gatewayIdDeviceId: string,
  customerId: string
) => {
  const endpoint = changeGatewayNameEndpoint
    .replace(":deviceId", gatewayIdDeviceId)
    .replace(":custId", customerId);
  return await Put<any, IChangeGatewayNameRequest>(endpoint, payload, {
    headers: {
      session: getSessionId(),
    },
  });
};
export const changeReaderNameService = async (
  payload: { deviceName: string; desciption?: string },
  gatewaydDeviceId: string,
  customerId: string,
  deviceId:string
) => {
  
  const endpoint = changeReaderNameEndpoint.replace(
    ":genDeviceId",
    gatewaydDeviceId
  ).replace(
    ":custId",
    customerId
  ).replace(
    ":deviceId",
    deviceId
  );
  return await Patch<any, { deviceName: string; desciption?: string }>(
    endpoint,
    payload,
    {
      headers: {
        session: getSessionId(),
      },
    }
  );
};
export const onboardGatewayService = async (
  gatewayIdDeviceId: string,
  customerId: string
) => {
  const endpoint = onboardGatewayEndpoint
    .replace(":deviceId", gatewayIdDeviceId)
    .replace(":custId", customerId);
  return await Post<any, {}>(
    endpoint,
    {},
    {
      headers: {
        session: getSessionId(),
      },
    }
  );
};
export const deboardGatewayService = async (
  gatewayIdDeviceId: string,
  customerId: string
) => {
  const endpoint = onboardGatewayEndpoint
    .replace(":deviceId", gatewayIdDeviceId)
    .replace(":custId", customerId);
  return await Delete<any>(endpoint, {
    headers: {
      session: getSessionId(),
    },
  });
};
export const saveToDraftService = async (payload: any) => {
  const endpoint = saveToDraftEndpoint;
  return await Post<any, {}>(endpoint, payload, {
    headers: {
      session: getSessionId(),
    },
  });
};

export const downloadGateway = async (customerId: string) => {
  const endpoint = downloadGatewayEndpoint.replace(":customer_id", customerId);
  return await FileDownload<any, any>(
    endpoint,
    {},
    {
      headers: {
        session: getSessionId(),
      },
    }
  );
};

export const reEmmitApiCallback = async (payload: {
  customerId: string;
  email: string;
}) => {
  const endpoint = pendingreEmmitCallback
    .replace(":customer_id", payload.customerId)
    .replace(":email", payload.email);
  return await Get<any, any>(
    endpoint,
    {},
    {
      headers: {
        session: getSessionId(),
      },
    },
    false
  );
};
export const deleteGateway = async (payload: {
  customerId: string;
  gatewayId: string;
}) => {
  const endpoint = changeGatewayNameEndpoint
    .replace(":custId", payload.customerId)
    .replace(":deviceId", payload.gatewayId);
  return await Delete<any>(
    endpoint,
    {
      headers: {
        session: getSessionId(),
      },
    },
  );
};
