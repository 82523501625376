import {
  Card,
  ContextualMenu,
  Divider,
  Iconography,
  SearchInput,
  Typography,
  Pagination,
} from "@hid-galaxy-ui/galaxy-react";
import { contextMenuItem } from "@hid-galaxy-ui/galaxy-react/components/ContextualMenu";
import { DividerVariants } from "@hid-galaxy-ui/galaxy-react/components/Divider/dividerEnums";
import { IconographyThemes } from "@hid-galaxy-ui/galaxy-react/components/Iconography";
import { IconographySizes } from "@hid-galaxy-ui/galaxy-react/components/Iconography/iconEnums";
import { TypographyVariantEnum } from "@hid-galaxy-ui/galaxy-react/components/Typography/typographyEnum";
import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  changeGatewayNameService,
  deboardGatewayService,
  deleteGateway,
  getGatewayDeviceService,
  onboardGatewayService,
} from "../../../../services/readerAPIService";
import { NAMESPACE } from "../../../../utils/i18nUtils";
import { IDevices } from "../downloadGateway/types";
import GateWayDeviceInfo from "./gateWayDeviceInfo";
import { useDispatch, useSelector } from "react-redux";
import {
  loginInfoData,
  setGlobalToasts,
  setSelectedGateway,
} from "../../../../reducers/userInfoReducer";
import { GATEWAY_DEFAULT_LISTING_COUNT } from "../../../../utils/commonConst";
import { buildNotification } from "../../../../utils/notification";
import {
  discoveredReadersData,
  resetReaders,
  setGatewayList,
  setGatewayPagination,
} from "../../../../reducers/discoverReadersReducer";
import { IDeviceInfo } from "./gateWayDeviceInfo/general";

interface IGateWayProps {
  onGateWayDeviceSelected: (deviceId: string) => any;
  selectedgatewayDevice?: string;
  onReaderDiscoveryBuGateway: (gatewayId: string) => void;
  prevReader?: any;
}

export enum DeviceStatus {
  ONBOARDED = "ONBOARDED",
  CREATED = "CREATED",
  DEBOARDED = "DEBOARDED",
}

export default function GateWayPanel(props: IGateWayProps) {
  const { t } = useTranslation(NAMESPACE.READER);
  const initialValues = {
    cloudConnectState: "",
    customerId: "",
    description: "",
    deviceId: "",
    links: [],
    logicalState: "",
    macAddress: "",
    name: "",
    serialNumber: "",
    siteId: "",
    systemId: "",
  };

  const [showDeviceInfo, setShowDeviceInfo] = useState<boolean>(false);
  const [selectedDevice, setSelectedDevice] = useState<IDevices>(initialValues);
  const [gateWayDevices, setGateWayDevices] = useState<IDevices[]>([]);
  const [matchedGateWayDevices, setMatchedGateWayDevices] = useState<
    IDevices[] | null
  >(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const pageSize: number = GATEWAY_DEFAULT_LISTING_COUNT;

  const timeout: any = useRef();
  const dispatch = useDispatch();
  const [searchVal, setSearchVal] = useState<string>("");
  const { selectedCustomerInfo, selectedGateway } = useSelector(loginInfoData);
  const { gatewayList, gatewayPagination, discoveryLoading } = useSelector(
    discoveredReadersData
  );
  const customerId = selectedCustomerInfo?.customerId?.toString();
  useEffect(() => {
    if (gatewayList) {
      // setMatchedGateWayDevices(gatewayList);
      // gatewayPagination?.totalRecords &&
      //   setTotalRecords(gatewayPagination.totalRecords);
      // gatewayPagination?.currentPage &&
      //   setCurrentPage(gatewayPagination.currentPage);
      customerId &&
        gatewayPagination?.currentPage &&
        fetchGatewayDevices(gatewayPagination.currentPage);
    } else {
      customerId && fetchGatewayDevices(1);
    }
  }, [customerId]);

  useEffect(() => {
    dispatch(
      setGatewayPagination({
        currentPage: currentPage,
        totalRecords: totalRecords,
      })
    );
  }, [totalRecords, currentPage, dispatch]);

  const fetchGatewayDevices = async (
    pageNumber: any,
    deviceName: string = "",
    searchTriggered: boolean = false
  ) => {
    try {
      let searchWithDeviceName =
        searchTriggered || deviceName ? deviceName : searchVal;
      const gatewayDevicesDetails: any = await getGatewayDeviceService(
        customerId,
        pageNumber,
        pageSize,
        searchWithDeviceName
      );
      if (gatewayDevicesDetails) {
        setGateWayDevices(
          gatewayDevicesDetails.data?.genericDevicesPayload?.devices
        );
        setMatchedGateWayDevices(
          gatewayDevicesDetails.data?.genericDevicesPayload?.devices
        );
        dispatch(
          setGatewayList(
            gatewayDevicesDetails.data?.genericDevicesPayload?.devices
          )
        );
        setTotalRecords(gatewayDevicesDetails.data?.totalRecords);
        setCurrentPage(pageNumber);
      }
    } catch (err: any) {
      if (err.response?.status !== 500) {
        if(err.response?.status === 403){
          const errMessageFromServer : string =  err && err.response && err.response.data && typeof(err.response.data) === "object" && err.response.data.statusDescription ? err.response.data.statusDescription : t("READERS.HIDADMIN_RESTRICTION");
          dispatch(setGlobalToasts([
            buildNotification("Error",errMessageFromServer)
          ]))
        }else {
          dispatch(
            setGlobalToasts([
              buildNotification("Error", t("READERS.REQUEST_FAILED")),
            ])
          );
        }
      }
    }
  }

  const onChangeNameAndDesc = async (data: IDeviceInfo) => {
    try {
      const payload = {
        name: data.name,
        description: data.description,
        deviceType: "GATEWAY",
        category: "GENERIC",
        protocol: "GATEWAY",
      };
      if (customerId) {
        const res = await changeGatewayNameService(
          payload,
          selectedDevice.deviceId,
          customerId
        );
        customerId && fetchGatewayDevices(currentPage);
        handleDeviceInfo();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const debouncingMethod = (cb: any, delay = 750) => {
    return function (...args: any) {
      clearTimeout(timeout.current);
      timeout.current = setTimeout((...args) => cb(...args), delay);
    };
  };

  const renderEmptyGateway = (message: string) => (
    <div className="hid-text-center hid-layout__mt-08">
      <div className="hid-layout__mt-05">
        <Iconography icon="networkWired" size={IconographySizes.Large} />
      </div>
      <span className="hid-origo__gateway-title hid-layout__mt-05">
        {t("READERS.READER_GATEWAYS")}
      </span>
      <Typography
        className="hid-layout__m-05"
        variant={TypographyVariantEnum.BodyShortProduct}
      >
        {message}
      </Typography>
    </div>
  );

  const contextualData = (device: IDevices) => {
    return [
      {
        data: [
          {
            key: "activate",
            value: t("READERS.ACTIVATE_GATEWAY"),
            submenu: [],
            isDisabled: device.logicalState === DeviceStatus.ONBOARDED,
          },
          {
            key: "delete",
            value: t("READERS.DELETE_GATEWAY"),
            submenu: [],
            isDisabled: device.logicalState === DeviceStatus.DEBOARDED,
          },
          {
            key: "discover",
            value: t("READERS.DISCOVER_READERS"),
            submenu: [],
            isDisabled:
              device.logicalState === DeviceStatus.DEBOARDED ||
              (device.deviceId === props.selectedgatewayDevice &&
                props.prevReader[device.deviceId]),
          },
          {
            key: "changeName",
            value: t("READERS.CHANGE_NAME"),
            submenu: [],
            isDisabled: device.logicalState === DeviceStatus.DEBOARDED,
          },
        ],
      },
    ];
  };

  const handleDeviceInfo = () => {
    setShowDeviceInfo(!showDeviceInfo);
  };

  const onboard = async (device: IDevices) => {
    try {
      if (customerId) {
        const res = await onboardGatewayService(device.deviceId, customerId);
        customerId && fetchGatewayDevices(currentPage);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const deboard = async (device: IDevices) => {
    try {
      if (customerId) {
        const payload = {
          customerId: customerId,
          gatewayId: device.deviceId,
        };
        const res = await deleteGateway(payload);
        customerId && fetchGatewayDevices(currentPage);
        if (selectedDevice.deviceId === device.deviceId) {
          dispatch(
            resetReaders({
              data: { readers: null, timestamp: Date.now().toString() },
              gateway: device.deviceId,
            })
          );
          dispatch(setSelectedGateway(""));
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleContexualMenuItemsAction = (
    data: contextMenuItem,
    device: IDevices
  ) => {
    setSelectedDevice(device);
    switch (data.key) {
      case "changeName":
        handleDeviceInfo();
        break;
      case "discover":
        props.onReaderDiscoveryBuGateway(device.deviceId);
        break;
      case "activate":
        onboard(device);
        break;
      case "delete":
        deboard(device);
        break;
    }
  };

  return (
    <Card cardSelectedIcon={<Iconography icon=" " />}>
      <div
        className="hid-origo__white-card hid-origo__linq-white-card-min-height"
        data-testid="gateway"
      >
        <Typography
          variant={TypographyVariantEnum.Label}
          data-testid="gateway-panel-title"
        >
          {t("READERS.GATEWAY_PANEL_TITLE")}
        </Typography>
        <div className="hid-spacing__mt-06">
          <Divider mode={DividerVariants.Canvas} />
        </div>

        <div className="hid-layout__mt-04">
          <SearchInput
            id="search_gateway_devices"
            name="search_gateway"
            value={searchVal}
            placeholder="Search Gateway devices"
            onSearch={(_, data: string) => {
              setSearchVal(data);
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSearchVal(event.target.value);
              debouncingMethod(() =>
                fetchGatewayDevices(1, event.target.value, true)
              )();
            }}
          />
        </div>
        {matchedGateWayDevices ? (
          matchedGateWayDevices.length ? (
            <div className="hid-grid">
              {matchedGateWayDevices.map((device: any) => {
                return (
                  <div
                    className="hid-grid hid-origo__min-width-100"
                    key={`mainDiv_${device.deviceId}_${device.customerId}`}
                  >
                    <div
                      className="hid-grid__column hid-grid__column--11-xs"
                      key={`div_${device.deviceId}_${device.customerId}`}
                      style={{ paddingRight: 0, paddingLeft: "24px" }}
                    >
                      <Card
                        variant={"selectable"}
                        cardSelectedIcon={<Iconography icon=" " />}
                        onClick={(e, val) =>
                          props.onGateWayDeviceSelected(device.deviceId)
                        }
                        selected={
                          device.deviceId === props.selectedgatewayDevice
                        }
                        isDisabled={
                          device.logicalState === DeviceStatus.DEBOARDED ||
                          (device.deviceId === props.selectedgatewayDevice &&
                            props.prevReader[device.deviceId])
                        }
                        className="hid-layout__mt-02"
                      >
                        <>
                          <Iconography icon="networkWired" size="Medium" />
                          <div className="hid-layout__mt-01">
                            <span className="hid-origo__gateway-label">
                              {device.name}
                            </span>
                          </div>
                          <div className="hid-flex hid-layout__mt-01">
                            <Iconography
                              icon="circleCheck"
                              size="Medium"
                              theme="Success"
                            />
                            {
                              <Typography
                                variant={
                                  TypographyVariantEnum.BodyShortMarketing
                                }
                                className="hid-origo__linq-success-status-text hid-layout__ml-02"
                              >
                                {device.logicalState === DeviceStatus.ONBOARDED
                                  ? t("READERS.ONBOARDED")
                                  : t("READERS.INACTIVE")}
                              </Typography>
                            }
                          </div>
                        </>
                      </Card>
                    </div>

                    <div
                      className="hid-grid__column hid-grid__column--1-xs"
                      style={{ paddingLeft: 0 }}
                      key={`contextMenu_${device.deviceId}_${device.customerId}`}
                    >
                      <ContextualMenu
                        contextualMenuData={contextualData(device)}
                        isToogle={true}
                        onClick={(e, item) =>
                          handleContexualMenuItemsAction(item, device)
                        }
                      >
                        <Iconography
                          icon="circleEllipsis"
                          size={IconographySizes.Medium}
                          theme={IconographyThemes.Info}
                        />
                      </ContextualMenu>
                    </div>
                  </div>
                );
              })}
              <div className="gateway-devices-pagination hid-grid__column hid-grid__column--12-xs">
                <Pagination
                  currentPageNumber={currentPage}
                  totalRecords={totalRecords}
                  pageSize={pageSize}
                  onPageNumberClick={(clickedPageNumber: any) =>
                    fetchGatewayDevices(clickedPageNumber)
                  }
                />
              </div>
            </div>
          ) : (
            renderEmptyGateway(t("READERS.READERS_DISPLAY_MESSAGE"))
          )
        ) : (
          renderEmptyGateway(t("READERS.NO_GATEWAY_MESSAGE"))
        )}
        {showDeviceInfo && (
          <GateWayDeviceInfo
            closeDeviceInfoModal={handleDeviceInfo}
            selectedDevice={selectedDevice}
            onChangeNameAndDesc={onChangeNameAndDesc}
          />
        )}
      </div>
    </Card>
  );
}
