import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "@hid-galaxy-ui/galaxy-css/galaxy.css";
import "./styles/index.scss";
import App from "./App";
import "./i18n";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { Loader } from "@hid-galaxy-ui/galaxy-react";
import { showOutdatedBrowserNotification } from "./utils/linq_utils";
import ErrorBoundary from "./ErrorBoundary";

const persistor = persistStore(store);
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
showOutdatedBrowserNotification();
root.render(
  <React.StrictMode>
    <ErrorBoundary>
    <Suspense fallback={<Loader />}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </Suspense>
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
