import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";

export interface InitialStateDataProps {
  linq_enabled_orgs: ICustomerInfo[];
  roles: string[];
  selectedCustomerInfo: ICustomerInfo | null;
  userInfo: IUserInfo | null;
  showOnPremInstaller: boolean;
  globalToasts: any[];
  selectedGateway: string;
  pendingRequests: any[];
  isUserLoggedIn: boolean;
}

interface ICustomerInfo {
  customerId: number;
  customerName: string;
}

interface IUserInfo {
  email: string;
  acceptedTOS?: boolean;
}


const initialState: InitialStateDataProps = {
  linq_enabled_orgs: [],
  roles: [],
  selectedCustomerInfo: null,
  userInfo: null,
  showOnPremInstaller: false,
  globalToasts: [],
  selectedGateway:"",
  pendingRequests: [],
  isUserLoggedIn: false
};

const userInfoSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    setLoginInfo: (state, action: PayloadAction<InitialStateDataProps>) => {
      const { linq_enabled_orgs, roles, userInfo, showOnPremInstaller,pendingRequests } = action.payload;
      state.roles = roles;
      state.linq_enabled_orgs = linq_enabled_orgs;
      state.userInfo = userInfo; 
      state.selectedCustomerInfo = null;
      state.showOnPremInstaller = showOnPremInstaller;
      state.pendingRequests = pendingRequests;
      state.isUserLoggedIn = true;
    },
    setSelectedCustomerInfo: (state, action: PayloadAction<ICustomerInfo>) => {
      state.selectedCustomerInfo = action.payload;
    },
    setUserLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isUserLoggedIn = action.payload;
    },
    logOut: (state) => {
      state.roles = [];
      state.linq_enabled_orgs = [];
      state.selectedCustomerInfo = null;
      state.showOnPremInstaller = false;
      state.isUserLoggedIn = false;
    },
    setGlobalToasts: (state, action: PayloadAction<any>) => {
      state.globalToasts = action.payload;
    },
    setSelectedGateway: (state, action: PayloadAction<string>) => {
      state.selectedGateway = action.payload;
    } ,
     resetLoginInfo: (state) => {
      state.linq_enabled_orgs =[];
      state.roles =[];
      state.globalToasts =[];
      state.selectedCustomerInfo =null;
      state.userInfo =null;
      state.showOnPremInstaller =false;
      state.selectedGateway ="";
      state.pendingRequests =[];
      state.isUserLoggedIn = false;
    },
    setPendingRequests: (state, action: PayloadAction<any>) => {
      state.pendingRequests = action.payload;
    }
  },
});

export default userInfoSlice.reducer;
export const { setLoginInfo, setSelectedCustomerInfo, logOut,setGlobalToasts,setSelectedGateway,resetLoginInfo, setPendingRequests } =
  userInfoSlice.actions;
export const loginInfoData = (state: RootState) => state.loginInfo;