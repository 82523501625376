import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Button,
  PasswordInput,
  TextField,
  Tooltip,
  TooltipPlacements,
  Typography,
} from "@hid-galaxy-ui/galaxy-react";
import { LogoThemes } from "@hid-galaxy-ui/galaxy-react/components/Logo/logoEnums";
import { TypographyVariantEnum } from "@hid-galaxy-ui/galaxy-react/components/Typography/typographyEnum";
import { validEmailPattern } from "../../utils/regexExpressions";
import { useLocation, useNavigate } from "react-router-dom";
import {
  changeOrganizationPath,
  homePath,
  loginPath,
} from "../../utils/routes";
import OrigoFooter from "../../widgets/OrigoFooter";
import { doLogin } from "../../services/loginAPIService";
import { getCommonNS, NAMESPACE } from "../../utils/i18nUtils";
import Notification from "@hid-galaxy-ui/galaxy-react/components/Notification";
import {
  NotificationWarning,
  buildNotification,
} from "../../utils/notification";
import TermsOfService from "./termsOfService";
import {
  loginInfoData,
  setGlobalToasts,
  setLoginInfo,
  setPendingRequests,
  setSelectedCustomerInfo,
} from "../../reducers/userInfoReducer";
import {
  LOGIN_TIMEOUT,
  READER_MANAGER_ADMIN_ROLE,
} from "../../utils/commonConst";
import {
  setUpgradeFirmwareProgressModalOpen,
} from "../../reducers/upgradeFirmwareReducer";
import { connect, connectToUpgradeFirmware } from "../../App";
import {
  applyTemplateData,
  setIsProgressModalOpen,
  setIsResetReaderProgressModalOpen,
  setRunningProcesses,
  setSelectedCorrelationId,
  setSelectedReaders,
} from "../../reducers/applyTemplateReducer";
import { clearReaders } from "../../reducers/discoverReadersReducer";

export enum renderPageEnum {
  MFA = "MFA",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  TERMS_OF_SERVICE = "TERMS_OF_SERVICE",
}
interface ICardential {
  email: string;
  password: string;
  validationError: { errorMessage: string; isValidEmail: boolean };
}
const initialValues = {
  email: "",
  password: "",
  validationError: { errorMessage: "", isValidEmail: false },
};
const Login = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { t } = useTranslation(NAMESPACE.USER);
  const navigation = useNavigate();
  const [toasts, setToasts] = useState([]) as any;
  const [renderPage, setRenderPage] = useState<String>();
  const [loginCredential, setLoginCredential] =
    useState<ICardential>(initialValues);

  const { userInfo, isUserLoggedIn } = useSelector(loginInfoData);
  const { runningProcesses } = useSelector(applyTemplateData);

  const handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.target as any;
    setLoginCredential({
      ...loginCredential,
      [target.name]: target.value,
      validationError: {
        isValidEmail: validateEmail(target.value),
        errorMessage: validateEmail(target.value)
          ? ""
          : t("COMMON.INVALID_EMAIL_ERROR", getCommonNS()),
      },
    });
  };

  const handleOnPasswordChange = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.target as any;
    setLoginCredential({
      ...loginCredential,
      [target.name]: target.value,
    });
  };
  const validateEmail = (email: string): boolean =>
    !!email.match(validEmailPattern);

  const showNotification = () => {
    
    const hideSessionTimeoutMessageOnPageRefresh:boolean | null = localStorage.getItem("isPageRefreshed") && toasts && toasts[0] && toasts[0]['message'] && toasts[0]['message'] === LOGIN_TIMEOUT ;
    
    if (hideSessionTimeoutMessageOnPageRefresh) {
      return null
    } else {
      return (
        <Notification
        toasts={toasts}
        setToasts={setToasts}
        isSticky={true}
        isAutoClose={false}
      />
    )}
  };
  const checkNavigate = (res: any, existingSession: any) => {
    if (res.installerInfo?.linq_enabled_orgs?.length > 1) {
      navigation(changeOrganizationPath, { state: { existingSession } });
    } else if (res.installerInfo?.linq_enabled_orgs?.length === 1) {
      dispatch(
        setSelectedCustomerInfo(res.installerInfo?.linq_enabled_orgs[0])
      );
      navigation(homePath, { state: { existingSession } });
    }
  };

  const getPendingRequestType = (type: string) => {
    switch (type) {
      case "update-firmware":
        return "upgrade";
      case "reset-firware":
        return "resetReader";
      default:
        return "applyTemplate";
    }
  };
  const getNextGroup = (pendingRequests: any[], current: any) => {
    const update = pendingRequests.filter(
      (c) => c.requestType === "update-firmware"
    );
    const id = update.findIndex((c) => c.requestId === current);
    return `Group ${id + 1}`;
  };
  const handleOnSign = async () => {
    if (loginCredential.password) {
      doLogin({
        userName: loginCredential.email,
        password: loginCredential.password,
      })
        .then((response: any) => {
          // const tokenId = response.headers.get("tokenId");
          const userData = response.data;
          const existingSession = response.headers.get("X-Session-User");
          if (
            // tokenId &&
            userData &&
            userData?.installerInfo?.linq_enabled_orgs?.length > 0
          ) {
            // localStorage.setItem("origo-session", `${tokenId};`);
            localStorage.setItem("userDetails", JSON.stringify(userData));
            localStorage.setItem(
              "acceptedTOS",
              JSON.stringify({ acceptedTos: !userData?.displayTos })
            );
            dispatch(
              setLoginInfo({
                ...userData.installerInfo,
                pendingRequests: userData.pendingRequests,
                userInfo: {
                  email: loginCredential.email,
                  acceptedTOS: !userData?.displayTos,
                },
                showOnPremInstaller:
                  userData &&
                  userData.installerInfo &&
                  userData.installerInfo.roles.includes(
                    READER_MANAGER_ADMIN_ROLE
                  ),
              })
            );
            if (userData.pendingRequests.length) {
              dispatch(setPendingRequests(userData.pendingRequests));
              for (let k = 0; k < userData.pendingRequests.length; k++) {
                const correlationId = userData.pendingRequests?.[k].requestId;
                const requestType = userData.pendingRequests?.[k].requestType;
                const customer_id = userData.pendingRequests?.[k].customerId;
                dispatch(setSelectedCorrelationId(correlationId));
                dispatch(
                  setRunningProcesses({
                    [correlationId]: {
                      isCompleted: false,
                      type: getPendingRequestType(requestType),
                      ...(requestType === "update-firmware" && {
                        name: runningProcesses
                          ? getNextGroup(
                              userData.pendingRequests,
                              correlationId
                            )
                          : "",
                      }), // Add the name key if requestType is upgrade
                    },
                  })
                );
                if (requestType === "update-firmware") {
                  dispatch(setUpgradeFirmwareProgressModalOpen(true));
                  connectToUpgradeFirmware(correlationId, customer_id);
                } else if (requestType === "reset") {
                  dispatch(setIsResetReaderProgressModalOpen(true))
                  dispatch(setSelectedCorrelationId(correlationId));
                  connect(correlationId, "resetReader");
                } else {
                  dispatch(
                    setSelectedReaders({
                      [correlationId]: {
                        readersCount:
                          userData?.pendingRequests?.[k].readersCount,
                        dcid: userData?.pendingRequests?.[k].dcid,
                      },
                    })
                  );
                  dispatch(setIsProgressModalOpen(true));
                  connect(correlationId, "applyTemplate", customer_id);
                }
              }
            }

            userData?.displayTos
              ? setRenderPage(renderPageEnum.TERMS_OF_SERVICE)
              : checkNavigate(userData, existingSession);
          } else {
            setToasts([
              buildNotification(
                "Error",
                t("LOGIN.NO_ORGANIZATIONS_CONFIGURED")
              ),
            ]);
          }
        })
        .catch((err: any) => {
          const message = err?.response
            ? err?.response.data.statusDescription
            : err?.message;
          if (err?.response?.status === 401) {
            setToasts(
              NotificationWarning(
                t("LOGIN.SIGNIN_INFO_ERROR_TEXT"),
                "ban",
                "FAILED"
              )
            );
          } else {
            setToasts([buildNotification("Error", message)]);
          }
        });
    }
  };

  const renderTypography = (
    children: string,
    variant: TypographyVariantEnum,
    className?: string
  ) => (
    <Typography variant={variant} {...(className && { className })}>
      {children}
    </Typography>
  );
  const getTKey = (): string => {
    return "LOGIN.SIGNIN";
  };

  const handleKeyPress = useCallback(
    (event: any) => {
      const { key } = event;
      if (key === "Enter") {
        handleOnSign();
      }
    },
    [loginCredential]
  );

   // this logic is for on clicking the back button/refresh it should go back to login page
   useEffect(() => {
    const handlePopState = (event:any) => {
      setRenderPage("")
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigation]);

  useEffect(() => {
    dispatch(setGlobalToasts([]));

    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);
  useEffect(() => {
    if (state?.type) {
      setToasts([buildNotification("Error", state?.type)]);
    }
    if (isUserLoggedIn && userInfo?.acceptedTOS) {
      navigation(homePath);
    } else {
      setRenderPage("")
      dispatch(clearReaders());
      navigation(loginPath);
    }
  }, []);

  const renderLoginpage = () => {
    return (
      <>
        <div className="hid-origo__login-main">
          <div className="hid-origo__login__intro">
            <div className="hid-origo__login__logo">
              {renderTypography(
                t("LOGIN.WELCOME_TEXT"),
                TypographyVariantEnum.H2,
                "hid-origo__login__logo-text hid-text-center"
              )}
            </div>
            <div className="hid-origo__login__welcome">
              {renderTypography(
                t("LOGIN.WELCOME_TEXT_BOTTOM"),
                TypographyVariantEnum.H1,
                "hid-origo__login__welcome-text"
              )}
              {renderTypography(
                t("LOGIN.CENTRALIZED_READER_MANAGEMENT"),
                TypographyVariantEnum.H2,
                "hid-origo__login__tagline-text"
              )}
            </div>
          </div>
          <div className="hid-origo__login__form">
            {renderTypography(
              t("LOGIN.SIGNIN"),
              TypographyVariantEnum.H3,
              "hid-spacing__mb-08"
            )}

            <Tooltip
              message={"This is the registered RM email id"}
              placement={TooltipPlacements.Top}
            >
              <TextField
                name="email"
                label={t("LOGIN.EMAIL_ID")}
                status={
                  loginCredential.validationError.errorMessage
                    ? "error"
                    : undefined
                }
                value={loginCredential?.email}
                id="email"
                helperText={loginCredential.validationError.errorMessage}
                placeholder="harrytruman@acmebrickco.com"
                onChange={handleOnChange}
              />
            </Tooltip>

            <div data-testid="password">
              <PasswordInput
                name="password"
                label={t("LOGIN.PASSWORD")}
                id="password"
                data-testid="password"
                value={loginCredential?.password}
                onChange={handleOnPasswordChange}
              />
            </div>

            {toasts.length > 0 && showNotification()}
            <div className="hid-origo__login-action-wrapper">
              <Button
                type="button"
                id="login-btn"
                disabled={!validateEmail(loginCredential?.email) || !loginCredential?.password }
                name={t(getTKey())}
                label={t(getTKey())}
                onClick={handleOnSign}
              />
            </div>
          </div>
        </div>
        <OrigoFooter />
      </>
    );
  };
  switch (renderPage) {
    case renderPageEnum.TERMS_OF_SERVICE:
      return <TermsOfService />;
    default:
      return renderLoginpage();
  }
};
export default Login;
