import {
  Button,
  DataTable,
  Iconography,
  Pagination,
  Typography,
} from "@hid-galaxy-ui/galaxy-react";
import { IconographyThemes } from "@hid-galaxy-ui/galaxy-react/components/Iconography";
import { TypographyVariantEnum } from "@hid-galaxy-ui/galaxy-react/components/Typography/typographyEnum";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Reader from "../../assets/images/reader.png";
import Template from "../../assets/images/template.png";
import { useLocation, useNavigate } from "react-router-dom";
import { NAMESPACE } from "../../utils/i18nUtils";
import {
  readersApplyTemplatePath,
  updateFirmwarePath,
} from "../../utils/routes";
import { ICoulmnData, ITableData } from "../../pages/Linq/readers";
import { setSelectedReaders } from "../../reducers/upgradeFirmwareReducer";
import { useDispatch, useSelector } from "react-redux";
import FirmwareCheckModal from "./firmwareCheckModal";
import { setPageFrom } from "../../reducers/discoverReadersReducer";
import { isUSBReader } from "../../utils/commonConst";
import MercuryCheckModal from "./MercuryCheckModal";
import { loginInfoData } from "../../reducers/userInfoReducer";

interface IPagination {
  currentPageNumber: number;
  totalRecords: number;
  pageSize: number;
}
interface IReaderTableProps {
  data: {
    id: string;
    fwDisplayName: string;
    dcid?: string;
    readerType: string;
    controllerName: string;
    lastUpdatedDate: string;
  }[];
  columnsData: ICoulmnData[];
  dependencies?: {
    handleActionClick: (rowData: ITableData) => void;
  };
  timestamp?: string | null;
}

interface IReadersData {
  id: string;
  fwDisplayName?: string;
  dcid?: string;
  readerType?: string;
  controllerName?: string;
  lastUpdatedDate?: string;
  operationAllowed?: boolean;
  serialNumber?: string;
  readerName?: string;
  isDisabled?: boolean;
}

export default function ReadersTable(props: IReaderTableProps) {
  const { t } = useTranslation(NAMESPACE.READER);
  const dispatch = useDispatch();
  const { data, columnsData, dependencies, timestamp } = props;
  const [selectedRows, setSelectedRows] = useState<IReadersData[]>([]);
  const [isFirmwareCheckModalOpen, setIsFirmwareCheckModalOpen] =
    useState<boolean>(false);
  const [isMercuryCheckModalOpen, setIsMercuryCheckModalOpen] =
    useState<boolean>(false);
  const[mercuryCheckModalMessage, setMercuryCheckModalMessage] = useState<string>("")
  const navigate = useNavigate();
  const location = useLocation();
  const [paginationConfig, setPaginationConfig] = useState<IPagination>({
    currentPageNumber: 1,
    totalRecords: data?.length,
    pageSize: 8,
  });

  const [tableData, setTableData] = useState<IReadersData[]>([]);
  const { selectedGateway } = useSelector(loginInfoData);
  useEffect(() => {
    const updateDData = data.map((data: IReadersData) => {
      return { ...data, operationAllowed: !data.operationAllowed };
    });
    if (paginationConfig.pageSize) {
      const pageData = updateDData.slice(
        (paginationConfig.currentPageNumber - 1) * paginationConfig.pageSize,
        (paginationConfig.currentPageNumber - 1) * paginationConfig.pageSize +
          paginationConfig.pageSize
      );
      setTableData(pageData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationConfig, data]);

  useEffect(() => {
    setPaginationConfig({ ...paginationConfig, currentPageNumber: 1 });
  }, [selectedGateway])

  const onPageNumberClick = (pageNumber: any) => {
    setPaginationConfig({ ...paginationConfig, currentPageNumber: pageNumber });
  };

  const rowSelection: {
    selectedRowKeys: string[];
    onSelect: (selectedRowKeys: any, currentRow: any, selected: any) => void;
    onSelectAll: (selectedRowKeys: any) => void;
  } = {
    selectedRowKeys: [],
    onSelect: (selectedRowKeys, currentRow, selected) => {
      if (selected) {
        setSelectedRows([...selectedRows, currentRow]);
      } else {
        const updatedRows = selectedRows.filter(
          (c: IReadersData) => c.id !== currentRow.id
        );
        setSelectedRows(updatedRows);
      }
    },
    onSelectAll: (selectedRowKeys) => {
      if (selectedRowKeys.length > selectedRows.length) {
        const selectedReaders = tableData.filter((c) =>
          selectedRowKeys.includes(c.id)
        );

        setSelectedRows([...selectedRows, ...selectedReaders]);
      } else {
        const selectedReaders = selectedRows.filter((c) =>
          selectedRowKeys.includes(c.id)
        );
        setSelectedRows(selectedReaders);
      }
    },
  };
  const updateFirmware = () => {
    const mercuryReaders = selectedRows.filter((reader:any)=>!isUSBReader(reader.port))
    if(mercuryReaders.length>=1){
      setIsMercuryCheckModalOpen(true)
      setMercuryCheckModalMessage(t("READERS.MERCURY_READER_ERROR"))
      return
    }else {
    const isSameFirmwareSelected = selectedRows.every(
      (reader) => reader.fwDisplayName === selectedRows[0].fwDisplayName
    );
    dispatch(setSelectedReaders(selectedRows));
    if (isSameFirmwareSelected) {
      dispatch(setPageFrom("list"));
      navigate(updateFirmwarePath);
    } else {
      setIsFirmwareCheckModalOpen(true);
    }
    return;
  }
  };
  const getDiscoveredTime = () => {
    if (timestamp) {
      let date = new Date(parseInt(timestamp));

      // Get hours and minutes
      let hours = date.getHours();
      let minutes = date.getMinutes();
      // Format hours and minutes with leading zeros
      let formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`;
      return formattedTime;
    }
  };

  return (
    <div className="hid-origo__reader-layout" data-testid="readers-table">
      <div className="hid-layout__p-03 hid-flex hid-flex-jc-between hid-flex-ai-center">
        <Typography
          variant={TypographyVariantEnum.H2}
          className="hid-layout__mt-01 "
        >
          {t("READERS.READERS")}
        </Typography>
        <span className="discovered-text">{`Discovered : ${getDiscoveredTime()}`}</span>
      </div>
      {selectedRows.length > 0 && (
        <div className="hid-origo__table-layout hid-layout__p-03 hid-flex hid-flex-jc-between">
          {location.pathname === "/readers" || location.pathname === "/" ? (
            <>
              <div className="hid-flex hid-flex-ai-center">
                <img src={Reader} alt="reader" />
                <Typography
                  className="hid-layout__ml-02"
                  variant={TypographyVariantEnum.BodyShortProduct}
                >
                  {selectedRows.length}
                  {t("READERS.READERS_SELECTED")}
                </Typography>
              </div>
              <div className="hid-flex hid-layout__mr-02">
                <div className="hid-flex hid-flex-ai-center hid-layout__mr-04">
                  <img src={Template} alt="template" />
                  <Button
                    label={t("READERS.APPLY_CONFIGURATION_ID")}
                    variant="secondary"
                    disabled
                    onClick={() => navigate(readersApplyTemplatePath)}
                  />
                </div>
                <div className="hid-flex hid-flex-ai-center no-underline">
                  <Iconography
                    icon="arrowsRepeat"
                    theme={IconographyThemes.Info}
                  />
                  <Button
                    label={t("READERS.UPDATE_FIRMWARE")}
                    variant="secondary"
                    // greying out update firmware if all selected is HID Controller
                    disabled={selectedRows.filter((reader:any)=>!isUSBReader(reader.port)).length === selectedRows.length}
                    title={selectedRows.filter((reader:any)=>!isUSBReader(reader.port)).length === selectedRows.length ? t("READERS.MERCURY_READER_ERROR") : null}
                    onClick={updateFirmware}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="hid-flex hid-flex-ai-center">
                <img src={Reader} alt="reader" />
                <Typography
                  className="hid-layout__ml-02"
                  variant={TypographyVariantEnum.BodyShortProduct}
                >
                  {selectedRows.length}
                  {t("READERS.READERS_CONFIGURATION_SELECTED")}
                </Typography>
              </div>
              <div className="hid-layout__mr-02">
                <Button
                  label={t("READERS.APPLY_TEMPLATE")}
                  variant="secondary"
                />
              </div>
            </>
          )}
        </div>
      )}
      <DataTable
        dataKey={"id"}
        isSelectable={tableData.length > 0 ? true : false}
        data={tableData}
        columns={columnsData}
        rowSelectionConfig={rowSelection}
        dependencies={dependencies}
        emptyHandlerInfo={{
          isEmpty: tableData.length === 0,
          renderError: () => (
            <span>{t("READERS.NO_READER_CONNECTED_ERROR")}</span>
          ),
        }}
        rowDisableKey="isDisabled"
      />
      {tableData.length > 0 ? (
        <Pagination
          currentPageNumber={paginationConfig.currentPageNumber || 0}
          totalRecords={data?.length}
          pageSize={paginationConfig.pageSize}
          onPageNumberClick={onPageNumberClick}
          border={true}
        />
      ) : null}
      {isFirmwareCheckModalOpen && (
        <FirmwareCheckModal
          onClose={() => setIsFirmwareCheckModalOpen(false)}
        />
      )}
         {isMercuryCheckModalOpen && (
        <MercuryCheckModal
          onClose={() => setIsMercuryCheckModalOpen(false)}
          message={mercuryCheckModalMessage}
        />
      )}
    </div>
  );
}
